import {
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import { EmptyList } from '../../ui/components/emptyList/EmptyList';
import { ClientProps } from '../../utils/types/type';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';

const postFilters = [
  <TextInput label="Recherche par nom" source="lastName" variant="outlined" />,
  <TextInput
    label="Recherche par prénom"
    source="firstName"
    variant="outlined"
  />,
  <TextInput label="Recherche par e-mail" source="email" variant="outlined" />,
];

interface UserListProps extends ClientProps {}

const PostListActions = ({ clientId }: UserListProps) => (
  <TopToolbar>
    <FilterButton />
    <UserCreate clientId={clientId} />
  </TopToolbar>
);

export const UserList = ({ clientId }: UserListProps) => {
  return (
    <List
      resource="users"
      actions={<PostListActions clientId={clientId} />}
      filter={{ client: clientId }}
      filters={postFilters}
      empty={
        <EmptyList
          title="Aucun utilisateur"
          addItemButton={<UserCreate clientId={clientId} />}
        />
      }
    >
      <Datagrid rowClick="" bulkActionButtons={false}>
        <TextField source="lastName" label="Nom" />
        <TextField source="firstName" label="Prénom" />
        <TextField source="email" label="E-Mail" />
        <ReferenceOneField label="Rôle" reference="roles" target="users">
          <TextField source="name" />
        </ReferenceOneField>
        <FunctionField
          render={({ id }: { id: string }) => (
            <UserEdit userId={id} clientId={clientId} />
          )}
        />
      </Datagrid>
    </List>
  );
};
