import { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { AddButton } from '../../ui/components/button/addButton/AddButton';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { ConfigurationForm } from './ConfigurationForm';
import useTransformConfigurationData from './hooks/useTransformConfigurationData.hook';

export const ConfigurationCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const { transformConfiguration } = useTransformConfigurationData();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Ajouter une configuration"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <AddButton
          label="Ajouter une configuration"
          onClick={() => setOpenPopup(true)}
        />
      }
    >
      <Create
        transform={transformConfiguration}
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Configuration créée', undefined, true);
            setOpenPopup(false);
          },
        }}
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <ConfigurationForm />
        </SimpleForm>
      </Create>
    </Popup>
  );
};
