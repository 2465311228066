import CampaignIcon from '@mui/icons-material/Campaign';

import { DocumentVersionCreate } from './DocumentVersionCreate';
import { DocumentVersionEdit } from './DocumentVersionEdit';
import { DocumentVersionList } from './DocumentVersionList';

export default {
  icon: CampaignIcon,
  list: DocumentVersionList,
  create: DocumentVersionCreate,
  edit: DocumentVersionEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
