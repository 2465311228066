import { Box, Grid } from '@mui/material';
import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const ServiceEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Service modifié', { type: 'success' });
    redirect('list', 'services');
  };

  const onError = (httpError: any) => {
    notify(httpError.body['hydra:description'], { type: 'error' });
  };

  return (
    <Edit
      mutationOptions={{
        onError: onError,
        onSuccess: onSuccess,
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="Services"
          redirect={() => redirect('list', 'services')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Nom" />
              <TextInput
                fullWidth
                source="name"
                label={false}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Description" />
              <TextInput
                fullWidth
                source="description"
                validate={required()}
                label={false}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Clients" />
              <ReferenceArrayInput source="clients" reference="clients">
                <AutocompleteArrayInput
                  label={false}
                  optionText="companyName"
                  optionValue="@id"
                />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
