import { Box, Grid } from '@mui/material';
import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  required,
  SimpleForm,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const StructureTagEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Tag de structure modifiée', 'structure-tags'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="Tag de Structure"
          redirect={() => redirect('list', 'structure-tags')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Structures" />
              <ReferenceArrayInput source="structures" reference="structures">
                <AutocompleteArrayInput
                  label={false}
                  optionText="name"
                  optionValue="@id"
                  fullWidth
                  validate={required()}
                />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
