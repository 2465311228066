import DescriptionIcon from '@mui/icons-material/Description';

import { MediaCreate } from './mediaCreate';
import { MediaEdit } from './mediaEdit';
import { MediaList } from './mediaList';

export default {
  icon: DescriptionIcon,
  list: MediaList,
  create: MediaCreate,
  edit: MediaEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
