import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <TextInput label="Recherche par code" source="code" />,
  <TextInput label="Recherche par label" source="label" />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const MediaTypeList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="code" label="Code" />
      <TextField source="label" label="Label" />
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
