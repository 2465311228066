import styled from 'styled-components';

export const StyleWrapperCancelButton = styled('div')`
  button {
    color: #000000;
    background-color: #ffffff;
    text-transform: none;
    border-color: #000000;
    font-weight: bold;
    &:hover {
      background-color: #ffffff;
    }
  }
`;
