import { Grid } from '@mui/material';
import { useState } from 'react';
import { Create, required, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { AddButton } from '../../ui/components/button/addButton/AddButton';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormTextInput } from '../../ui/components/form/formTextInput/FormTextInput';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { validateSiren } from '../../utils/validation/validation';
import { clientStatus } from './definitions';

export const ClientCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Ajouter un client"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <AddButton
          label="Ajouter un client"
          onClick={() => setOpenPopup(true)}
        />
      }
    >
      <Create
        title="Ajouter un client"
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Client créé', undefined, true);
            setOpenPopup(false);
          },
        }}
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Nom" required />
              <FormTextInput source="companyName" validate={required()} />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Statut" required />
              <FormAutocompleteInput
                source="status"
                validate={required()}
                choices={clientStatus}
                defaultValue="active"
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Siren" required />
              <FormTextInput source="siren" validate={validateSiren} />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Popup>
  );
};
