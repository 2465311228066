import { FC, PropsWithChildren } from 'react';

import { StyledFormLabel } from './FormLabel.style';

export interface FormLabelProps extends PropsWithChildren {
  label: string;
  required?: boolean;
}

export const FormLabel: FC<FormLabelProps> = ({
  label,
  required,
}: FormLabelProps) => {
  return (
    <StyledFormLabel>
      {label} {required && '*'}
    </StyledFormLabel>
  );
};
