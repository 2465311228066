import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import {
  Admin,
  EditGuesser,
  ListGuesser,
  Resource,
  TranslationMessages,
} from 'react-admin';
import { BrowserRouter } from 'react-router-dom';

import authProvider from './authentification/authProvider';
import LoginPage from './authentification/LoginPage';
import dataProvider from './dataProvider';
import address from './resources/address';
import client from './resources/client';
import configuration from './resources/configuration';
import damItem from './resources/dam-item';
import documentVersion from './resources/document-version';
import media from './resources/media';
import mediaAttribute from './resources/media-attribute';
import mediaAttributeDefinition from './resources/media-attribute-definition';
import mediaCategory from './resources/media-category';
import mediaType from './resources/media-type';
import notification from './resources/notification';
import permission from './resources/permission';
import pimItem from './resources/pim-item';
import role from './resources/role';
import service from './resources/service';
import StructureDigitalAttribute from './resources/Structure-digital-attribute';
import structureTag from './resources/structure-tag';
import structureType from './resources/structure-type';
import user from './resources/user';
import AppLayout from './ui/layout/appLayout/AppLayout';
import { theme } from './ui/styles/theme';

const i18nProvider = polyglotI18nProvider(
  () => fr as TranslationMessages,
  'fr',
  {
    allowMissing: true,
  }
);

const App = () => (
  <BrowserRouter>
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      i18nProvider={i18nProvider}
      title="OneManager"
      layout={AppLayout}
      requireAuth
    >
      <Resource name="clients" {...client} />
      <Resource name="configurations" {...configuration} />
      <Resource name="services" {...service} />
      <Resource name="pim-items" {...pimItem} />
      <Resource name="dam-items" {...damItem} />
      <Resource name="users" {...user} />
      <Resource name="roles" {...role} />
      <Resource name="permissions" {...permission} />
      <Resource name="notifications" {...notification} />
      <Resource name="document-versions" {...documentVersion} />
      <Resource name="media-types" {...mediaType} />
      <Resource name="media-categories" {...mediaCategory} />
      <Resource name="medias" {...media} />
      <Resource
        name="media-attribute-definitions"
        {...mediaAttributeDefinition}
      />
      <Resource name="media-attributes" {...mediaAttribute} />

      <Resource name="structure-types" {...structureType} />
      <Resource name="structure-tags" {...structureTag} />
      <Resource
        name="structure-digital-attributes"
        {...StructureDigitalAttribute}
      />
      <Resource name="addresses" {...address} />
      <Resource
        name="social-media-auth-requests"
        list={ListGuesser}
        edit={EditGuesser}
      />
      <Resource
        name="social-media-auth-tokens"
        list={ListGuesser}
        edit={EditGuesser}
      />
      <Resource
        name="digital-attributes"
        list={ListGuesser}
        edit={EditGuesser}
      />
    </Admin>
  </BrowserRouter>
);
export default App;
