import { Box, IconButton, styled } from '@mui/material';

export const StyledPopupTitle = styled('div')`
  flex-grow: 1;
`;

export const StyledPopupIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.grey[500]};
`;

export const StyledPopupHeader = styled(Box)`
  display: flex;
  align-items: flex-start;

  padding-block: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(3)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding-block: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(3)};
  }
`;
