import { Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { DamFileMimeType } from './definitions';

export const DamItemCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Dam item créé', 'dam-items'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter Dam item
        </Typography>
        <TextInput fullWidth source="name" label="Nom" validate={required()} />
        <ReferenceInput source="client" reference="clients">
          <AutocompleteInput
            optionText="companyName"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceArrayInput
          label="pim items"
          source="pimItems"
          reference="pim-items"
        >
          <AutocompleteArrayInput
            optionText="name"
            optionValue="@id"
            fullWidth
          />
        </ReferenceArrayInput>
        <AutocompleteInput
          label="Mime type"
          source="mimeType"
          fullWidth
          validate={required()}
          choices={DamFileMimeType}
        />

        <NumberInput
          fullWidth
          source="kiloByteFileSize"
          label="Taille du fichier"
          validate={required()}
          min={0}
        />
        <TextInput
          fullWidth
          source="fileThumbnailUrl"
          label="Url vignette du fichier"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="fileUrl"
          label="URL du fichier"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
