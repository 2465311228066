import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { ServiceCreate } from './ServiceCreate';
import { ServiceEdit } from './ServiceEdit';
import { ServiceList } from './ServiceList';

export default {
  icon: ManageAccountsIcon,
  create: ServiceCreate,
  edit: ServiceEdit,
  list: ServiceList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
