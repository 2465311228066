import ClearAllIcon from '@mui/icons-material/ClearAll';

import { MediaCategoryCreate } from './MediaCategoryCreate';
import { MediaCategoryEdit } from './MediaCategoryEdit';
import { MediaCategoryList } from './MediaCategoryList';

export default {
  icon: ClearAllIcon,
  list: MediaCategoryList,
  create: MediaCategoryCreate,
  edit: MediaCategoryEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
