import { Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const RoleCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Rôle créé', 'roles'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un rôle
        </Typography>
        <TextInput
          fullWidth
          source="name"
          label="Nom du rôle"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="description"
          label="Description du rôle"
          validate={required()}
        />
        <ReferenceArrayInput source="permissions" reference="permissions">
          <AutocompleteArrayInput
            fullWidth
            optionText="reference"
            optionValue="@id"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
