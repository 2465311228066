import { FC, useState } from 'react';
import { Create, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { AddButton } from '../../ui/components/button/addButton/AddButton';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { ClientProps } from '../../utils/types/type';
import { UserForm } from './userForm';

interface UserCreateProps extends ClientProps {}

export const UserCreate: FC<UserCreateProps> = ({ clientId }) => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Ajouter un utilisateur"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <AddButton
          label="Ajouter un utilisateur"
          onClick={() => setOpenPopup(true)}
        />
      }
    >
      <Create
        resource="users"
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Utilisateur créée', undefined, true);
            setOpenPopup(false);
          },
        }}
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <UserForm clientId={clientId} action="create" />
        </SimpleForm>
      </Create>
    </Popup>
  );
};
