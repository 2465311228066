import { Dispatch, SetStateAction } from 'react';
import { Button, useRedirect, useResourceContext } from 'react-admin';

import { StyleWrapperCancelButton } from './CancelButoon.style';

interface CancelButtonProps {
  openPopup?: Dispatch<SetStateAction<boolean>>;
  label?: string;
}

export const CancelButton = ({
  openPopup,
  label = 'Annuler',
}: CancelButtonProps) => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  return (
    <StyleWrapperCancelButton>
      <Button
        label={label}
        onClick={() =>
          openPopup ? openPopup(false) : redirect('list', resource)
        }
        variant="text"
        color="secondary"
        size="medium"
      />
    </StyleWrapperCancelButton>
  );
};
