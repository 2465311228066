import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import { MediaTypeEdit } from './MediaTypeEdit';
import { MediaTypeList } from './MediaTypeList';

export default {
  icon: LocalOfferIcon,
  list: MediaTypeList,
  edit: MediaTypeEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
