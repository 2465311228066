import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceInput,
  ReferenceOneField,
  TextField,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <ReferenceInput label="Recherche par auteur" source="user" reference="users">
    <AutocompleteInput optionText="fullName" optionValue="@id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Recherche par document"
    source="document"
    reference="documents"
  >
    <AutocompleteInput optionText="id" optionValue="@id" />
  </ReferenceInput>,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const DocumentVersionList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceOneField
        label="Auteur"
        reference="users"
        target="documentVersions"
        sortBy="user.lastName"
      >
        <TextField source="fullName" />
      </ReferenceOneField>
      <ReferenceOneField
        label="Document"
        reference="documents"
        target="versions"
        sortBy="document.id"
      >
        <TextField source="name" />
      </ReferenceOneField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
