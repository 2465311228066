import { Box, Grid } from '@mui/material';
import {
  Edit,
  required,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  useGetOne,
  useGetRecordId,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormTextInput } from '../../ui/components/form/formTextInput/FormTextInput';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { validateSiren } from '../../utils/validation/validation';
import { UserList } from '../user/UserList';
import { clientStatus } from './definitions';

export const ClientEdit = () => {
  const redirect = useRedirect();
  const recordId = useGetRecordId();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const { data: client } = useGetOne('clients', { id: recordId });

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Client modifié', 'clients'),
      }}
      mutationMode="pessimistic"
      sx={{ '& .MuiPaper-root': { boxShadow: 'none' } }}
    >
      <Box mt={3}>
        <BackButton
          label={client?.companyName}
          redirect={() => redirect('list', 'clients')}
        />
        <TabbedShowLayout>
          <Tab label="Informations">
            <SimpleForm toolbar={<FormToolbar />}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 12, md: 12 }}
              >
                <Grid item xs={4}>
                  <FormLabel label="Nom" required />
                  <FormTextInput source="companyName" validate={required()} />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel label="Siren" required />
                  <FormTextInput source="siren" validate={validateSiren} />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel label="Statut" required />
                  <FormAutocompleteInput
                    source="status"
                    validate={required()}
                    choices={clientStatus}
                    defaultValue="active"
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </Tab>
          <Tab label="Utilisateurs">
            <UserList clientId={recordId} />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Edit>
  );
};
