import {
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const StructureTagList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceManyField
        label="Structures"
        reference="structures"
        target="structureTags"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
