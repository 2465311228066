import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const PimItemCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('PimItem créé', 'pim-items'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter PimItem
        </Typography>
        <ReferenceInput
          source="client"
          reference="clients"
          validate={required()}
        >
          <AutocompleteInput
            optionText="companyName"
            optionValue="@id"
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput source="damItem" reference="dam-items" fullwidth>
          <AutocompleteInput optionText="name" optionValue="@id" fullWidth />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="name"
          label="Nom du produit"
          validate={required()}
        ></TextInput>
        <TextInput
          fullWidth
          source="description"
          label="Description du produit"
        ></TextInput>
        <TextInput
          fullWidth
          source="brand"
          label="Marque du produit"
        ></TextInput>
        <NumberInput
          fullWidth
          source="price"
          label="Prix du produit (à l'unité)"
        ></NumberInput>
        <NumberInput
          fullWidth
          source="priceByKilogram"
          label="Prix du produit (au kg)"
        ></NumberInput>
      </SimpleForm>
    </Create>
  );
};
