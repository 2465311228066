import {
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <TextInput label="Recherche par référence" source="reference" />,
  <TextInput label="Recherche par description" source="description" />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const PermissionList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="reference" label="Référence" />
      <TextField source="description" label="Description" />
      <ReferenceManyField label="Rôle" reference="roles" target="permissions">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
