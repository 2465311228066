import { Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const PermissionCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      title="Ajouter une permission"
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Permission créée', 'permissions'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Informations
        </Typography>
        <TextInput
          fullWidth
          source="reference"
          label="Référence de la permission"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="description"
          label="Description de la permission"
          validate={required()}
        />
        <ReferenceArrayInput source="roles" reference="roles">
          <AutocompleteArrayInput
            fullWidth
            optionText="name"
            optionValue="@id"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
