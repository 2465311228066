import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { MediaAttributeCreate } from './MediaAttributeCreate';
import { MediaAttributeEdit } from './MediaAttributeEdit';
import { MediaAttributeList } from './MediaAttributeList';

export default {
  icon: TextSnippetIcon,
  list: MediaAttributeList,
  create: MediaAttributeCreate,
  edit: MediaAttributeEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
