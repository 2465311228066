import { Box, Grid } from '@mui/material';
import {
  AutocompleteInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const PimItemEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('PimItem modifié', 'pim-items'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="PimItems"
          redirect={() => redirect('list', 'pim-items')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Client" />
              <ReferenceInput
                source="client"
                reference="clients"
                validate={required()}
              >
                <AutocompleteInput
                  optionText="companyName"
                  optionValue="@id"
                  label={false}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Dam Item" />
              <ReferenceInput source="damItem" reference="dam-items">
                <AutocompleteInput
                  optionText="name"
                  optionValue="@id"
                  label={false}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Name" />
              <TextInput
                fullWidth
                source="name"
                label={false}
                placeholder="Nom du produit"
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Description" />
              <TextInput
                fullWidth
                source="description"
                label={false}
                placeholder="Description du produit"
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Marque" />
              <TextInput
                fullWidth
                source="brand"
                label={false}
                placeholder="Marque du produit"
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Prix (à l'unité)" />
              <NumberInput
                fullWidth
                source="price"
                label={false}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Prix (au kg)" />
              <NumberInput
                fullWidth
                source="priceByKilogram"
                label={false}
                validate={required()}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
