import ShortTextIcon from '@mui/icons-material/ShortText';

import { MediaAttributeDefinitionCreate } from './MediaAttributeDefinitionCreate';
import { MediaAttributeDefinitionEdit } from './MediaAttributeDefinitionEdit';
import { MediaAttributeDefinitionList } from './MediaAttributeDefinitionList';

export default {
  icon: ShortTextIcon,
  list: MediaAttributeDefinitionList,
  create: MediaAttributeDefinitionCreate,
  edit: MediaAttributeDefinitionEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
