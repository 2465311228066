import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useState } from 'react';
import { email, Form, required, useLogin } from 'react-admin';

import { FormLabel } from '../ui/components/form/formLabel/FormLabel';
import { FormPasswordInput } from '../ui/components/form/formPasswordInput/FormPasswordInput';
import { FormTextInput } from '../ui/components/form/formTextInput/FormTextInput';
import { LoginLayout } from '../ui/layout/loginLayout/LoginLayout';
import { Colors } from '../ui/styles/tokens';

const LoginPage: FC = () => {
  const login = useLogin();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (email: string, password: string) => {
    setIsLoading(true);
    setError(false);
    login({ email, password }).catch(() => {
      setError(true);
      setIsLoading(false);
    });
  };

  return (
    <LoginLayout>
      <Form onSubmit={({ email, password }) => handleSubmit(email, password)}>
        <Box mb={5}>
          <Typography variant="h2">Se connecter</Typography>
        </Box>
        <Box>
          <FormLabel label="Adresse mail" required />
          <FormTextInput
            inputProps={{ 'data-testid': 'email' }}
            source="email"
            validate={[required(), email()]}
          />
        </Box>
        <Box mt={3}>
          <FormLabel label="Mot de passe" required />
          <FormPasswordInput
            inputProps={{ 'data-testid': 'password' }}
            source="password"
            validate={required()}
          />
        </Box>
        <Box textAlign="center" height="30px">
          {error && (
            <Typography variant="body1" color="error">
              Email ou mot de passe incorrect
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Button
            sx={{ backgroundColor: Colors.ColorBlack }}
            variant="contained"
            type="submit"
            fullWidth
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={27} /> : false}
          >
            Se connecter
          </Button>
        </Box>
      </Form>
    </LoginLayout>
  );
};

export default LoginPage;
