import { Fonts, SYSTEM_FONT_STACK } from './tokens';

export function getFontStack(font: Fonts): string {
  return [font, ...SYSTEM_FONT_STACK]
    .map((name) => (name.indexOf(' ') !== -1 ? `"${name}"` : name))
    .join(',');
}

export function remValue(rem: number) {
  return `${rem}rem`;
}
