import { Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SimpleForm,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const StructureTagCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Tag de structure créé', 'structure-tags'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un tag de structure
        </Typography>
        <ReferenceArrayInput source="structures" reference="structures">
          <AutocompleteArrayInput
            optionText="name"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
