import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { StyledContentBox, StyledMainWrapper } from './LoginLayout.style';

export interface LoginLayoutProps {
  children: ReactElement;
}

export const LoginLayout: FC<LoginLayoutProps> = ({ children }) => {
  return (
    <div>
      <Container maxWidth={false} disableGutters>
        <StyledMainWrapper>
          <Grid container>
            <Grid xs={12} md={7} item className="header-wrapper">
              <header>
                <Box className="baseline-wrapper">
                  <Typography color="white">
                    <Trans
                      components={[<span />]}
                      i18nKey="ui.Layout.baseLine"
                    />
                  </Typography>
                </Box>
              </header>
            </Grid>
            <Grid xs={12} md={5} item className="content-wrapper">
              <StyledContentBox>
                <Box width="100%">{children}</Box>
              </StyledContentBox>
            </Grid>
          </Grid>
        </StyledMainWrapper>
      </Container>
    </div>
  );
};
