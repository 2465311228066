import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const MediaCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Media créé', 'medias'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter une categorie media
        </Typography>
        <TextInput fullWidth source="name" label="Nom" validate={required()} />
        <TextInput fullWidth source="label" label="Label" />
        <ReferenceInput
          source="mediaCategory"
          reference="media-categories"
          fullwidth
        >
          <AutocompleteInput
            label="Catégorie"
            optionText="label"
            optionValue="@id"
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
