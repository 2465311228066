import styled from 'styled-components';

export const StyleWrapperAddButton = styled('div')`
  button {
    color: #000000;
    background-color: #FFFFFF;
    text-transform: none;
    border-color: #000000;
    font-weight: bold;
    marginRight: 3rem

    &:hover {
      background-color: #FFFFFF;
    }
  }
`;
