import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { MediaAttributeDefinitionCode } from './definitions';

export const MediaAttributeDefinitionCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif(
            `Définition de l'attribut créée`,
            'media-attribute-definitions'
          ),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter une définition
        </Typography>
        <TextInput
          fullWidth
          source="label"
          label="Label"
          validate={required()}
        />
        <AutocompleteInput
          source="code"
          fullWidth
          validate={required()}
          choices={MediaAttributeDefinitionCode}
        />
        <ReferenceInput source="mediaType" reference="media-types">
          <AutocompleteInput
            optionText="label"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
