import { Box, styled } from '@mui/material';

import bg from '../../../assets/loginBackground.svg';

export const StyledMainWrapper = styled(Box)`
  .layout-container {
    padding: 0;
  }
  .header-wrapper,
  .content-wrapper {
    min-height: 100vh;
  }
  .header-wrapper {
    background: url(${bg}) top left / cover no-repeat;
  }

  .baseline-wrapper {
    text-align: left;
    padding: ${({ theme }) => theme.spacing(3)};
    max-width: 600px;
    margin: 0 auto;
    p {
      font-size: 3rem;
      line-height: 2.5rem;
      font-family: 'ScandiaWebRegular';

      span {
        font-size: 3.2rem;
        font-family: 'ScandiaWebBold';
        font-weight: bold;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .content-wrapper {
      min-height: auto;
      & > div {
        padding: ${({ theme }) => theme.spacing(3)};
      }
    }
    .header-wrapper {
      min-height: 440px;
      background: url(${bg}) center center / cover no-repeat;
    }
    .baseline-wrapper {
      max-width: 400px;
      p {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
  }
`;

export const StyledContentBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 0.3rem;
  width: 100%;
  min-height: calc(100vh - 64px);
  & > div {
    width: 100%;
    max-width: 385px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: auto;
  }
`;
