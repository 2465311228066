import NotificationsIcon from '@mui/icons-material/Notifications';

import { NotificationCreate } from './NotificationCreate';
import { NotificationEdit } from './NotificationEdit';
import { NotificationList } from './NotificationList';

export default {
  icon: NotificationsIcon,
  list: NotificationList,
  edit: NotificationEdit,
  create: NotificationCreate,
  recordRepresentation: ({ id }: { id: string }) => id,
};
