import { FC, useState } from 'react';
import { Edit, EditButton, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { AddressForm } from './AddressForm';

interface AddressEditProps {
  addressId: string;
}

export const AddressEdit: FC<AddressEditProps> = ({ addressId }) => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Modifier une configuration"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <EditButton label="Modifier" onClick={() => setOpenPopup(true)} />
      }
    >
      <Edit
        id={addressId}
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Adresse addresse', undefined, true);
            setOpenPopup(false);
          },
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <AddressForm />
        </SimpleForm>
      </Edit>
    </Popup>
  );
};
