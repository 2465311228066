import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { RoleCreate } from './RoleCreate';
import { RoleEdit } from './RoleEdit';
import { RoleList } from './RoleList';

export default {
  list: RoleList,
  icon: AccountBoxIcon,
  create: RoleCreate,
  edit: RoleEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
