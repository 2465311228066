import { getBase64 } from '../../../utils/getBase64/getBase64';
import { BinaryFile } from '../../../utils/types/type';

const useTransformConfigurationData = () => {
  const transformConfiguration = async (data: { clientLogo: BinaryFile }) => ({
    ...data,
    ...(data?.clientLogo && {
      logoBase64: await getBase64(data.clientLogo.rawFile),
    }),
  });

  return { transformConfiguration };
};

export default useTransformConfigurationData;
