import { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { AddButton } from '../../ui/components/button/addButton/AddButton';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { AddressForm } from './AddressForm';

export const AddressCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Ajouter une adresse"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <AddButton
          label="Ajouter une adresse"
          onClick={() => setOpenPopup(true)}
        />
      }
    >
      <Create
        resource="addresses"
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Adresse créée', undefined, true);
            setOpenPopup(false);
          },
        }}
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <AddressForm />
        </SimpleForm>
      </Create>
    </Popup>
  );
};
