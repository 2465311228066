import { useNotify, useRedirect, useRefresh } from 'react-admin';

const useSuccessNotif = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const successNotif = (
    message: string,
    resource?: string,
    isRefresh?: boolean
  ) => {
    notify(message, { type: 'success' });
    resource && redirect('list', resource);
    isRefresh && refresh();
  };

  return { successNotif };
};

export default useSuccessNotif;
