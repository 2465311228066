import SettingsIcon from '@mui/icons-material/Settings';

import { ConfigurationCreate } from './ConfigurationCreate';
import { ConfigurationEdit } from './ConfigurationEdit';
import { ConfigurationList } from './ConfigurationList';

export default {
  icon: SettingsIcon,
  create: ConfigurationCreate,
  edit: ConfigurationEdit,
  list: ConfigurationList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
