export const StructureDigitalAttributeType = [
  {
    id: 'facebook_short_lived_user_access_token',
    name: 'facebook_short_lived_user_access_token',
  },
  {
    id: 'facebook_long_lived_user_access_token',
    name: 'facebook_long_lived_user_access_token',
  },
  {
    id: 'facebook_long_lived_page_access_token',
    name: 'facebook_long_lived_page_access_token',
  },
  {
    id: 'acebook_long_lived_page_access_token',
    name: 'acebook_long_lived_page_access_token',
  },
  { id: 'facebook_page_id', name: 'facebook_page_id' },
  { id: 'google_access_token', name: 'google_access_token' },
  { id: 'google_refresh_token', name: 'google_refresh_token' },
  { id: 'google_page_id', name: 'google_page_id' },
];
