import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  ReferenceOneField,
  SingleFieldList,
  TextField,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <ReferenceInput
    label="Recherche par client"
    source="client"
    reference="clients"
  >
    <AutocompleteInput optionText="companyName" optionValue="@id" />
  </ReferenceInput>,
  <ReferenceArrayInput
    label="Recherche par pimItems"
    source="pim-items"
    reference="pim-items"
  >
    <AutocompleteArrayInput optionText="id" optionValue="@id" />
  </ReferenceArrayInput>,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const DamItemList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="Nom" />
      <ReferenceOneField
        sortBy="client.companyName"
        label="Client"
        reference="clients"
        target="damItems"
      />
      <ReferenceManyField
        label="Pim items"
        reference="pim-items"
        target="damItem"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
