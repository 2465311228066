import { Box } from '@mui/material';
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  SimpleForm,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const NotificationEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Notification modifiée', 'notifications'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={2}>
        <BackButton
          label="Notification"
          redirect={() => redirect('list', 'notifications')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <FormLabel label="Utilisateur" />
          <ReferenceInput source="user" reference="users">
            <AutocompleteInput
              fullWidth
              label={false}
              optionText="lastName"
              optionValue="@id"
              validate={required()}
            />
          </ReferenceInput>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
