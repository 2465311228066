import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { StyledEmptyListIcon } from './EmptyList.style';

export interface EmptyListProps {
  title: string;
  addItemButton: JSX.Element;
}

export const EmptyList: FC<EmptyListProps> = ({ title, addItemButton }) => {
  return (
    <Box textAlign="center" m="auto">
      <StyledEmptyListIcon>
        <ContentCopyOutlinedIcon />
      </StyledEmptyListIcon>

      <Typography variant="h5">{title} </Typography>
      <Box my={2}> {addItemButton}</Box>
    </Box>
  );
};
