import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const NotificationCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Notification créée', 'notifications'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter une Notification
        </Typography>

        <ReferenceInput source="user" reference="users" fullwidth>
          <AutocompleteInput
            label="Utilisateur"
            optionText="lastName"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
