import StoreIcon from '@mui/icons-material/Store';

import { StructureTypeCreate } from './StructureTypeCreate';
import { StructureTypeEdit } from './StructureTypeEdit';
import { StructureTypeList } from './StructureTypeList';

export default {
  icon: StoreIcon,
  create: StructureTypeCreate,
  edit: StructureTypeEdit,
  list: StructureTypeList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
