import { Box, Typography } from '@mui/material';
import { AppBar, AppBarProps, Layout, LayoutProps } from 'react-admin';

import { Colors } from '../../styles/tokens';

const CustomAppBar = (props: AppBarProps) => (
  <AppBar {...props} color="primary" elevation={1}>
    <Typography
      variant="h6"
      color="inherit"
      sx={{
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    />
    <Box component="span" sx={{ flex: 1, fontWeight: 'bold' }}>
      OneManager
    </Box>
  </AppBar>
);

const AppLayout = (props: LayoutProps) => (
  <Layout
    sx={{ backgroundColor: Colors.ColorGrey50 }}
    {...props}
    appBar={CustomAppBar}
  />
);

export default AppLayout;
