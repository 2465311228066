import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useGetList,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { StructureDigitalAttributeType } from './definitions';

export const StructureDigitalAttributeCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  const { total } = useGetList('structures');

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif(
            'Attribute digital créé',
            'structure-digital-attributes'
          ),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un attribut digital
        </Typography>
        <ReferenceInput
          source="structure"
          reference="structures"
          perPage={total}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <AutocompleteInput
          label="Type"
          source="type"
          fullWidth
          validate={required()}
          choices={StructureDigitalAttributeType}
        />
        <TextInput fullWidth source="value" label="Valeur" />
      </SimpleForm>
    </Create>
  );
};
