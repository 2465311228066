import LocationOnIcon from '@mui/icons-material/LocationOn';

import { AddressCreate } from './AddressCreate';
import { AddressEdit } from './AddressEdit';
import { AddressList } from './AddressList';

export default {
  icon: LocationOnIcon,
  list: AddressList,
  create: AddressCreate,
  edit: AddressEdit,
  recordRepresentation: ({ id }: { id: string }) => id,
};
