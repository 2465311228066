import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  ImageField,
  List,
  ReferenceInput,
  ReferenceOneField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import { EmptyList } from '../../ui/components/emptyList/EmptyList';
import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';
import { ConfigurationCreate } from './ConfigurationCreate';
import { ConfigurationEdit } from './ConfigurationEdit';

const postFilters = [
  <ReferenceInput
    label="Recherche par client"
    source="client"
    reference="clients"
  >
    <AutocompleteInput optionText="companyName" optionValue="@id" />
  </ReferenceInput>,
  <TextInput
    label="Recherche par e-mail du directeur de clientèle"
    source="customerRelationshipManagerEmail"
    resettable
  />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ConfigurationCreate />
  </TopToolbar>
);

export const ConfigurationList = () => (
  <List
    filters={postFilters}
    actions={<PostListActions />}
    empty={
      <EmptyList
        title="Aucune Configuration"
        addItemButton={<ConfigurationCreate />}
      />
    }
  >
    <Datagrid rowClick="" bulkActionButtons={false}>
      <ImageField label="Logo client" source="logo" />
      <ReferenceOneField
        sortBy="client.companyName"
        label="Client"
        reference="clients"
        target="configuration"
      />
      <TextField
        source="customerRelationshipManagerEmail"
        label="E-mail du directeur de clientèle"
      />
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField
        render={({ id }: { id: string }) => (
          <ConfigurationEdit configurationId={id} />
        )}
      />
    </Datagrid>
  </List>
);
