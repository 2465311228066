import {
  AutocompleteInput,
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import { EmptyList } from '../../ui/components/emptyList/EmptyList';
import { AddressCreate } from './AddressCreate';
import { AddressEdit } from './AddressEdit';
import { AddressType, CountryCode } from './definitions';

const postFilters = [
  <TextInput label="Recherche par nom" source="name" resettable />,
  <TextInput label="Recherche par adresse" source="addressLine1" resettable />,
  <TextInput
    label="Recherche par complément d'adresse"
    source="addressLine2"
    resettable
  />,
  <TextInput label="Recherche par ville" source="cityName" resettable />,
  <TextInput
    label="Recherche par code postal"
    source="postalCode"
    resettable
  />,
  <AutocompleteInput
    label=" Recherche par code pays"
    source="countryCode"
    choices={CountryCode}
  />,
  <AutocompleteInput
    label=" Recherche par type"
    source="addressType"
    choices={AddressType}
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <FilterButton />
    <AddressCreate />
  </TopToolbar>
);

export const AddressList = () => (
  <List
    filters={postFilters}
    actions={<PostListActions />}
    empty={
      <EmptyList title="Aucune opération" addItemButton={<AddressCreate />} />
    }
  >
    <Datagrid bulkActionButtons={false} rowClick="">
      <TextField source="name" label="Nom" />
      <TextField source="addressLine1" label="Adresse" />
      <TextField source="addressLine2" label="Complément d'adresse" />
      <TextField source="cityName" label="Ville" />
      <TextField source="postalCode" label="Code postal" />
      <TextField source="countryCode" label="Code pays" />
      <TextField source="addressType" label="Type" />
      <ReferenceOneField
        label="Structure"
        reference="structures"
        target="addresses"
      >
        <TextField source="name" />
      </ReferenceOneField>
      <FunctionField
        render={({ id }: { id: string }) => <AddressEdit addressId={id} />}
      />
    </Datagrid>
  </List>
);
