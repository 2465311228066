import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
} from 'react-admin';

const postFilters = [<TextInput label="Recherche par type" source="Type" />];

export const StructureDigitalAttributeList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceOneField
        label="Structure"
        reference="structures"
        target="structureDigitalAttributes"
      >
        <TextField source="name" />
      </ReferenceOneField>
      <TextField source="type" label="Type" />
      <TextField source="value" label="Valeur" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
