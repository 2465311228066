import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceInput,
  ReferenceOneField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <ReferenceInput
    label="Recherche par client"
    source="client"
    reference="clients"
  >
    <AutocompleteInput optionText="companyName" optionValue="@id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Recherche par dam item"
    source="damItems"
    reference="dam-items"
  >
    <AutocompleteInput optionText="id" optionValue="@id" />
  </ReferenceInput>,
  <TextInput label="Recherche par nom" source="name" />,
  <TextInput label="Recherche par marque" source="brand" />,
  <TextInput label="Recherche par prix" source="price" />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

export const PimItemList = () => (
  <List filters={postFilters} exporter={false} actions={<PostListActions />}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceOneField
        sortBy="client.companyName"
        label="Client"
        reference="clients"
        target="pimItems"
      />
      <ReferenceOneField
        label="Dam Item"
        reference="dam-items"
        target="pimItems"
      >
        <TextField source="name" />
      </ReferenceOneField>
      <TextField source="name" label="Nom du produit"></TextField>
      <TextField source="brand" label="Marque du produit"></TextField>
      <TextField source="price" label="Prix du produit (unité)"></TextField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
