import { Typography } from '@mui/material';
import {
  Create,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const StructureTypeCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Type de structure créé', 'structure-types'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un type de structure
        </Typography>
        <TextInput
          fullWidth
          source="label"
          label="Label"
          validate={required()}
        />
        <TextInput fullWidth source="code" label="Code" validate={required()} />
        <NumberInput
          fullWidth
          source="position"
          label="Position"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
