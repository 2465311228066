import KeyIcon from '@mui/icons-material/Key';

import { PermissionCreate } from './PermissionCreate';
import { PermissionEdit } from './PermissionEdit';
import { PermissionList } from './PermissionList';

export default {
  list: PermissionList,
  icon: KeyIcon,
  create: PermissionCreate,
  edit: PermissionEdit,
};
