import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Breakpoint, Dialog, Divider, Typography } from '@mui/material';
import { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import {
  StyledPopupHeader,
  StyledPopupIconButton,
  StyledPopupTitle,
} from './Popup.style';

export interface PopupProps {
  title: string;
  children: ReactElement;
  openPopup: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  width?: Breakpoint;
  button?: ReactElement;
}

export const Popup: FC<PopupProps> = ({
  title,
  children,
  openPopup,
  setOpenPopup,
  width = 'md',
  button,
}) => {
  return (
    <>
      {button}
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        fullWidth
        maxWidth={width}
      >
        <StyledPopupHeader>
          <StyledPopupTitle>
            <Typography variant="h3">{title}</Typography>
          </StyledPopupTitle>
          <StyledPopupIconButton onClick={() => setOpenPopup(false)}>
            <CloseIcon />
          </StyledPopupIconButton>
        </StyledPopupHeader>
        <Divider />
        {children}
      </Dialog>
    </>
  );
};
