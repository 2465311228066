import { Box, Grid } from '@mui/material';
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { MediaAttributeDefinitionCode } from './definitions';

export const MediaAttributeDefinitionEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif(
            `Définition de l'attribut modifiée`,
            'media-attribute-definitions'
          ),
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="Définition"
          redirect={() => redirect('list', 'media-attribute-definitions')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Label" />
              <TextInput fullWidth source="label" label={false} />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Code" />
              <AutocompleteInput
                label={false}
                source="code"
                fullWidth
                validate={required()}
                choices={MediaAttributeDefinitionCode}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Type de media" />
              <ReferenceInput source="mediaType" reference="media-types">
                <AutocompleteInput
                  label={false}
                  optionText="label"
                  optionValue="@id"
                  fullWidth
                  validate={required()}
                />
              </ReferenceInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
