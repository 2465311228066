import { Divider } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { CancelButton } from '../../components/button/cancelButton/CancelButton';
import { SaveButton } from '../../components/button/saveButton/SaveButton';
import { StyleWrapperFormToolbar } from './FormToolbar.style';

export interface FormToolbarProps {
  openPopup?: Dispatch<SetStateAction<boolean>>;
}

export const FormToolbar = ({ openPopup }: FormToolbarProps) => {
  return (
    <>
      {openPopup && <Divider />}
      <StyleWrapperFormToolbar>
        <CancelButton openPopup={openPopup} />
        <SaveButton />
      </StyleWrapperFormToolbar>
    </>
  );
};
