import { FC, useState } from 'react';
import { Edit, EditButton, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { ClientProps } from '../../utils/types/type';
import { UserForm } from './userForm';

interface UserEditProps extends ClientProps {
  userId: string;
}

export const UserEdit: FC<UserEditProps> = ({ userId, clientId }) => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Modifier un utilisateur"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <EditButton label="Modifier" onClick={() => setOpenPopup(true)} />
      }
    >
      <Edit
        id={userId}
        resource="users"
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Utilisateur modifié', undefined, true);
            setOpenPopup(false);
          },
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <UserForm clientId={clientId} action="edit" />
        </SimpleForm>
      </Edit>
    </Popup>
  );
};
