declare global {
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

/**
 * Returns the value of a public environment variable.
 *
 * @param {string} env - The name of the environment variable to retrieve.
 * @returns {string | undefined} - The value of the environment variable.
 */
export const getPublicEnv = (env: string): string | undefined =>
  window?.env?.[env] ?? process.env?.[env];
