import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export interface BackButtonProps {
  redirect: () => void;
  label?: string;
}

export const BackButton: FC<BackButtonProps> = ({ redirect, label }) => {
  return (
    <Box display="flex">
      <ArrowBackIcon
        sx={{
          marginBottom: 'auto',
          marginTop: 'auto',
        }}
        onClick={redirect}
      />
      <Typography
        sx={{
          marginBottom: 'auto',
          marginTop: 'auto',
        }}
        variant="h2"
        gutterBottom
      >
        {label}
      </Typography>
    </Box>
  );
};
