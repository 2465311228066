import { useNotify } from 'react-admin';

const useErrorNotif = () => {
  const notify = useNotify();

  const errorNotif = (httpError: any) => {
    notify(httpError.body['hydra:description'], { type: 'error' });
  };

  return { errorNotif };
};

export default useErrorNotif;
