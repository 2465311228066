import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { MediaCategoryCode } from './definitions';

export const MediaCategoryCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Categorie de media créée', 'media-categories'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter une Categorie de media
        </Typography>
        <TextInput
          fullWidth
          source="label"
          label="Label"
          validate={required()}
        />
        <FormLabel label="code" required />
        <FormAutocompleteInput source="code" choices={MediaCategoryCode} />
        <ReferenceInput source="mediaType" reference="media-types">
          <AutocompleteInput
            optionText="label"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
