import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <TextInput label="Recherche par nom" source="name" />,
  <TextInput label="Recherche par label" source="label" />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const MediaList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="Nom" />
      <TextField source="label" label="Label" />
      <ReferenceOneField
        label="Categorie"
        reference="media-categories"
        target="medias"
      >
        <TextField source="label" />
      </ReferenceOneField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
