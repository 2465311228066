import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceArrayInput,
  ReferenceOneField,
  TextField,
} from 'react-admin';

import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';

const postFilters = [
  <ReferenceArrayInput
    label="Recherche par utilisateurs"
    source="user"
    reference="users"
  >
    <AutocompleteArrayInput
      label="Recherche par utilisateurs"
      optionText="lastName"
      optionValue="@id"
    />
  </ReferenceArrayInput>,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

export const NotificationList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceOneField
        label="Utilisateur"
        reference="users"
        target="notifications"
      >
        <TextField source="lastName" />
      </ReferenceOneField>
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
