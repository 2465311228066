import styled from 'styled-components';

export const StyleWrapperSaveButton = styled('div')`
  button {
    color: #ffffff;
    background-color: #000000;
    text-transform: none;

    &:hover {
      background-color: #000000;
    }
  }
`;
