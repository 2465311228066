import {
  email,
  maxLength,
  minLength,
  regex,
  required,
  Validator,
} from 'react-admin';

export const validateEmail = [required(), email()];

export const validateSiren = [
  required(),
  minLength(9),
  maxLength(9),
  regex(/^\d{9}$/, 'Doit être composé uniquement de chiffres'),
];

export const validateSiret: (siren: string) => Validator[] = (
  siren: string
) => [
  required(),
  minLength(14),
  maxLength(14),
  (value: string) =>
    !value.startsWith(siren)
      ? 'Les 9 premiers caractères doivent correspondre au siren du client'
      : undefined,
  regex(/^\d{14}$/, 'Doit être composé uniquement de chiffres'),
];

export const validateGeolocation = (value: number): string | undefined => {
  const chaine = value?.toString();
  const position = chaine?.search(/[.,]\d+$/);
  if (chaine?.substring(position + 1).length < 6)
    return 'Minimum 6 chiffres après la virgule';
  return undefined;
};
