import { FC } from 'react';
import { DateInput } from 'react-admin';

interface DateRangeFilterProps {
  label: string;
  source: string;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({ source }) => {
  const before = `${source}Before`;
  const after = `${source}After`;

  return (
    <>
      <DateInput label="Du" source={after} />
      <DateInput label="Au" source={before} />
    </>
  );
};
