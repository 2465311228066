import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { StructureDigitalAttributeCreate } from './StructureDigitalAttributeCreate';
import { StructureDigitalAttributeEdit } from './StructureDigitalAttributeEdit';
import { StructureDigitalAttributeList } from './StructureDigitalAttributeList';

export default {
  icon: AccountTreeIcon,
  create: StructureDigitalAttributeCreate,
  edit: StructureDigitalAttributeEdit,
  list: StructureDigitalAttributeList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
