import { Box, Grid } from '@mui/material';
import {
  Edit,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const StructureTypeEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Type de structure modifié', 'structure-types'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="Type de Structure"
          redirect={() => redirect('list', 'structure-types')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Label" />
              <TextInput
                fullWidth
                source="label"
                label={false}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Code" />
              <TextInput
                fullWidth
                source="code"
                label={false}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Position" />
              <NumberInput
                fullWidth
                source="position"
                label={false}
                validate={required()}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
