import { FC, useState } from 'react';
import { Edit, EditButton, SimpleForm } from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { Popup } from '../../ui/components/dialog/popup/Popup';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';
import { ConfigurationForm } from './ConfigurationForm';
import useTransformConfigurationData from './hooks/useTransformConfigurationData.hook';

interface ConfigurationEditProps {
  configurationId: string;
}

export const ConfigurationEdit: FC<ConfigurationEditProps> = ({
  configurationId,
}) => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();
  const { transformConfiguration } = useTransformConfigurationData();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Popup
      title="Modifier une configuration"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      button={
        <EditButton label="Modifier" onClick={() => setOpenPopup(true)} />
      }
    >
      <Edit
        id={configurationId}
        transform={transformConfiguration}
        mutationOptions={{
          onError: errorNotif,
          onSuccess: () => {
            successNotif('Configuration modifiée', undefined, true);
            setOpenPopup(false);
          },
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<FormToolbar openPopup={setOpenPopup} />}>
          <ConfigurationForm action="edit" />
        </SimpleForm>
      </Edit>
    </Popup>
  );
};
