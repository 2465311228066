import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormImageInput } from '../../ui/components/form/FormImageInput/FormImageInput';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const DocumentVersionCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Version de document créée', 'document-versions'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter une version de document
        </Typography>
        <ReferenceInput source="user" reference="users">
          <AutocompleteInput
            label="Auteur"
            optionText="fullName"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceInput source="document" reference="documents">
          <AutocompleteInput
            label="Document"
            optionText="name"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <FormImageInput
          imageInputSource="file"
          imageFieldSource="src"
          title="title"
          label="Visuel"
        />
      </SimpleForm>
    </Create>
  );
};
