import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ClientCreate } from './ClientCreate';
import { ClientEdit } from './ClientEdit';
import { ClientList } from './ClientList';

export default {
  list: ClientList,
  icon: AccountCircleIcon,
  create: ClientCreate,
  edit: ClientEdit,
  recordRepresentation: ({ companyName }: { companyName: string }) =>
    companyName,
};
