import { Box, styled } from '@mui/material';

import { Colors } from '../../styles/tokens';

export const StyledEmptyListIcon = styled(Box)`
  width: 10.2rem;
  height: 10.2rem;
  border-radius: 100%;
  color: ${Colors.ColorViolet};
  background-color: rgba(193, 172, 231, 0.3);
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    font-size: 5.3rem;
  }
`;
