import BackupIcon from '@mui/icons-material/Backup';
import { Card } from '@mui/material';
import { FC } from 'react';
import { ImageField, ImageInput } from 'react-admin';

import { FormLabel } from '../formLabel/FormLabel';

interface FormImageInputProps {
  imageInputSource: string;
  imageFieldSource: string;
  title: string;
  label: string;
  sourceUrl?: string;
  isFileExist?: boolean;
}

export const FormImageInput: FC<FormImageInputProps> = ({
  imageInputSource,
  imageFieldSource,
  title,
  sourceUrl,
  isFileExist,
  label,
}) => (
  <>
    <FormLabel label={label} />
    <Card variant="outlined" sx={{ width: '100%', marginTop: '8px' }}>
      <ImageInput
        sx={{ textAlign: 'center' }}
        label={false}
        source={imageInputSource}
        placeholder={
          <>
            <p>
              Déposez l&apos;image à uploader, ou cliquez pour la sélectionner.
            </p>
            <BackupIcon color="primary" fontSize="large" />
          </>
        }
        accept="image/jpg,image/jpeg,image/png"
      >
        <ImageField source={imageFieldSource} title={title} />
      </ImageInput>
      {!isFileExist && sourceUrl && (
        <ImageField
          source={sourceUrl}
          src={imageFieldSource}
          sx={{ textAlign: 'center' }}
        />
      )}
    </Card>
  </>
);
