import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const MediaAttributeCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif(`Attribut créé`, 'media-attributes'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un attribut
        </Typography>
        <ReferenceInput
          source="mediaAttributeDefinition"
          reference="media-attribute-definitions"
        >
          <AutocompleteInput
            label="Label d'attribut"
            optionText="label"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceInput source="media" reference="medias">
          <AutocompleteInput
            optionText="name"
            optionValue="@id"
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="value"
          label="Valeur"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
