import { Grid } from '@mui/material';
import { FC } from 'react';
import { FormDataConsumer, ReferenceInput } from 'react-admin';

import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormImageInput } from '../../ui/components/form/FormImageInput/FormImageInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormTextInput } from '../../ui/components/form/formTextInput/FormTextInput';
import { validateEmail } from '../../utils/validation/validation';

interface ConfigurationFormProps {
  action?: 'edit' | 'create';
}

export const ConfigurationForm: FC<ConfigurationFormProps> = ({ action }) => (
  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
    <Grid item xs={4}>
      <FormLabel label="Client" required />
      <ReferenceInput source="client" reference="clients">
        <FormAutocompleteInput
          optionText="companyName"
          optionValue="@id"
          disabled={action === 'edit'}
        />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="E-mail du directeur de clientèle" required />
      <FormTextInput
        source="customerRelationshipManagerEmail"
        placeholder="exemple@company.com"
        validate={validateEmail}
      />
    </Grid>
    <Grid item xs={8}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <FormImageInput
              imageInputSource="clientLogo"
              imageFieldSource="src"
              title="title"
              sourceUrl="logo"
              isFileExist={!!formData?.clientLogo}
              label="Logo client"
            />
          );
        }}
      </FormDataConsumer>
    </Grid>
  </Grid>
);
