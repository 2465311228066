import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

import { Colors } from './tokens';
import { configureTypographyPresets } from './typography';

export const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: Colors.ColorViolet,
    },
  },
  components: {
    ...defaultTheme.components,
    MuiPaper: {
      styleOverrides: {
        root: { boxShadow: 'none' },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
        fullWidth: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: { height: 'auto' },
      },
    },
  },
});

configureTypographyPresets(theme);
