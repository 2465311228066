import { Box, Grid } from '@mui/material';
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const DocumentVersionEdit = () => {
  const redirect = useRedirect();
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () =>
          successNotif('Version de document modifiée', 'document-versions'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={3}>
        <BackButton
          label="Version de document"
          redirect={() => redirect('list', 'document-versions')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Auteur" />
              <ReferenceInput source="user" reference="users">
                <AutocompleteInput
                  label={false}
                  optionText="fullName"
                  optionValue="@id"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Document" />
              <ReferenceInput source="document" reference="documents">
                <AutocompleteInput
                  label={false}
                  optionText="name"
                  optionValue="@id"
                />
              </ReferenceInput>
            </Grid>
            {/*<Grid item xs={4}>
              <FormDataConsumer>
                  {({ formData }) => {
                    return (
                      <FormImageInput
                        imageInputSource="file"
                        imageFieldSource="src"
                        title="title"
                        sourceUrl="thumbnailVisualUrl"
                        isFileExist={!!formData.file}
                        label="Visuel"
                      />
                    );
                  }}
                </FormDataConsumer>
            </Grid>*/}
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
