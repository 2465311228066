import BookmarkIcon from '@mui/icons-material/Bookmark';

import { StructureTagCreate } from './StructureTagCreate';
import { StructureTagEdit } from './StructureTagEdit';
import { StructureTagList } from './StructureTagList';

export default {
  icon: BookmarkIcon,
  create: StructureTagCreate,
  edit: StructureTagEdit,
  list: StructureTagList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
