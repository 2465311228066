import {
  Datagrid,
  DateField,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import { EmptyList } from '../../ui/components/emptyList/EmptyList';
import { DateRangeFilter } from '../../ui/components/filter/DateRangeFilter';
import { ClientCreate } from './ClientCreate';
import { clientStatus } from './definitions';

const postFilters = [
  <TextInput label="Recherche par nom" source="companyName" />,
  <TextInput label="Recherche par numéro de siren" source="siren" resettable />,
  <SelectInput
    label="Recherche par statut"
    source="status"
    resettable
    choices={clientStatus}
  />,
  <DateRangeFilter label="Recherche par date de création" source="createdAt" />,
  <DateRangeFilter
    label="Recherche par date de modification"
    source="updatedAt"
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ClientCreate />
  </TopToolbar>
);

export const ClientList = () => (
  <List
    filters={postFilters}
    actions={<PostListActions />}
    empty={<EmptyList title="Aucun client" addItemButton={<ClientCreate />} />}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="companyName" label="Nom" />
      <TextField source="siren" label="Siren" />
      <FunctionField
        label="Status"
        render={({ status }: { status: string }) =>
          status === 'active' ? 'Actif' : 'Inactif'
        }
      />
      <DateField source="createdAt" label="Créé le" />
      <DateField source="updatedAt" label="Modifié le" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
