import WorkIcon from '@mui/icons-material/Work';

import { DamItemCreate } from './DamItemCreate';
import { DamItemEdit } from './DamItemEdit';
import { DamItemList } from './DamItemList';

export default {
  icon: WorkIcon,
  create: DamItemCreate,
  edit: DamItemEdit,
  list: DamItemList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
