import { FC, ReactElement } from 'react';
import { SaveButton as SaveButtomFromReactAdmin } from 'react-admin';

import { StyleWrapperSaveButton } from './SaveButton.style';

export interface SaveButtonProps {
  label?: string;
  saving?: boolean;
  icon?: ReactElement;
}

export const SaveButton: FC<SaveButtonProps> = ({
  label = 'Enregistrer',
  saving,
  icon,
}) => (
  <StyleWrapperSaveButton>
    <SaveButtomFromReactAdmin icon={icon} label={label} saving={saving} />
  </StyleWrapperSaveButton>
);
