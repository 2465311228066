import { getPublicEnv } from '../utils/const/env';

export default {
  login: async (params: { email: string; password: string }) => {
    try {
      const { email, password } = params;

      const response = await fetch(
        `${getPublicEnv('REACT_APP_API_URL')}/ms/login`,
        {
          method: 'POST',
          body: JSON.stringify({ email, password }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const { token } = await response.json();
      localStorage.setItem('token', token);
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },

  checkError: (error: unknown) => {
    const status = (error as unknown as Record<string, string | number>).status;
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    if (localStorage.getItem('token')) return Promise.resolve();
    else return Promise.reject();
  },

  getPermissions: () => Promise.resolve(),
};
