import { ForwardRefRenderFunction, PropsWithoutRef } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
} from 'react-admin';

export const FormAutocompleteArrayInput: ForwardRefRenderFunction<
  HTMLInputElement,
  PropsWithoutRef<AutocompleteArrayInputProps>
> = ({ ...props }) => {
  return <AutocompleteArrayInput {...props} fullWidth label={false} />;
};
