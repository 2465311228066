import { Box, Grid } from '@mui/material';
import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useGetOne,
  useGetRecordId,
  useRedirect,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { BackButton } from '../../ui/components/button/backButton/BackButton';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const RoleEdit = () => {
  const redirect = useRedirect();
  const recordId = useGetRecordId();
  const { data: roles } = useGetOne('roles', { id: recordId });
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Edit
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Role modifié', 'roles'),
      }}
      mutationMode="pessimistic"
    >
      <Box m={1}>
        <BackButton
          label={roles?.name}
          redirect={() => redirect('list', 'roles')}
        />
        <SimpleForm toolbar={<FormToolbar />}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8 }}
          >
            <Grid item xs={4}>
              <FormLabel label="Nom" />
              <TextInput
                fullWidth
                source="name"
                label={false}
                placeholder="Admin"
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel label="Description" />
              <TextInput
                fullWidth
                source="description"
                label={false}
                placeholder="Description du rôle"
                validate={required()}
              />
            </Grid>
            <Grid item xs={8}>
              <FormLabel label="Permissions" />
              <ReferenceArrayInput source="permissions" reference="permissions">
                <AutocompleteArrayInput
                  label={false}
                  optionText="reference"
                  optionValue="@id"
                />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
