import { Grid } from '@mui/material';
import { FC } from 'react';
import { ReferenceArrayInput, ReferenceInput, required } from 'react-admin';

import { FormAutocompleteArrayInput } from '../../ui/components/form/formAutocompleteArrayInput/FormAutocompleteArrayInput';
import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormTextInput } from '../../ui/components/form/formTextInput/FormTextInput';
import { ClientProps } from '../../utils/types/type';
import { validateEmail } from '../../utils/validation/validation';

interface UserFormProps extends ClientProps {
  action: 'create' | 'edit';
}

export const UserForm: FC<UserFormProps> = ({ clientId, action }) => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 8 }}
    >
      <Grid item xs={4}>
        <FormLabel label="Client" required />
        <ReferenceInput
          source="client"
          reference="clients"
          validate={required()}
        >
          <FormAutocompleteInput
            defaultValue={`/clients/${clientId}`}
            optionText="companyName"
            optionValue="@id"
            disabled={!!clientId}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Nom" required />
        <FormTextInput source="lastName" validate={required()} />
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Prénom" required />
        <FormTextInput source="firstName" validate={required()} />
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Adresse e-mail" required />
        <FormTextInput source="email" validate={validateEmail} />
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Rôle" required />
        <ReferenceInput source="role" reference="roles">
          <FormAutocompleteInput
            optionText="name"
            optionValue="@id"
            validate={required()}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Mot de passe" required={action === 'create'} />
        <FormTextInput
          autoComplete="new-password"
          source="password"
          type="password"
          validate={action === 'create' ? required() : undefined}
        />
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Structures" />
        <ReferenceArrayInput
          source="structures"
          reference="structures"
          filter={{ client: clientId }}
        >
          <FormAutocompleteArrayInput optionText="name" optionValue="@id" />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  );
};
