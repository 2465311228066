import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
} from 'react-admin';

const postFilters = [<TextInput label="Recherche par valeur" source="name" />];

export const MediaAttributeList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceOneField
        label="Label d'attribut"
        reference="media-attribute-definitions"
        target="mediaAttributes"
      >
        <TextField source="label" />
      </ReferenceOneField>
      <ReferenceOneField
        label="Media"
        reference="medias"
        target="mediaAttributes"
      >
        <TextField source="name" />
      </ReferenceOneField>
      <TextField source="value" label="Valeur" />
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
