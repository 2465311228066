import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';

import { getPublicEnv } from './utils/const/env';

const apiUrl = getPublicEnv('REACT_APP_API_URL');
export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/ld+json' });
  }

  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const {
      createdAtBefore = undefined,
      createdAtAfter = undefined,
      updatedAtBefore = undefined,
      updatedAtAfter = undefined,
      ...filters
    } = {
      ...params.filter,
    };

    const query = {
      ...filters,
      ...(createdAtBefore ? { 'createdAt[before]': createdAtBefore } : {}),
      ...(createdAtAfter ? { 'createdAt[after]': createdAtAfter } : {}),
      ...(updatedAtBefore ? { 'updatedAt[before]': updatedAtBefore } : {}),
      ...(updatedAtAfter ? { 'updatedAt[after]': updatedAtAfter } : {}),
      page: page,
      itemsPerPage: perPage,
      [`order[${field}]`]: order,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json['hydra:member'],
      total: json['hydra:totalItems'],
    }));
  },

  getOne: (resource: string, params: any) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json['hydra:member'],
      total: json['hydra:totalItems'],
    }));
  },

  getManyReference: (resource: string, params: any) => {
    const query = {
      [`${params.target}.id`]: params.id,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json['hydra:member'],
      total: json['hydra:totalItems'],
      lable: 'getManyReference',
    }));
  },

  create: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  update: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
