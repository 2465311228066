export const SYSTEM_FONT_STACK = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
];

export const REM_BASE_PX = 16;
export const SPACING_INTERVAL = 8;

export enum Colors {
  ColorBlue = '#033FE2',
  ColorSky = '#9ED6FB',
  ColorYellow = '#E3F450',
  ColorLavande = '#C1ACE7',
  ColorViolet = '#8552F6',
  ColorGreen = '#4AA23E',
  ColorBlack = '#000000',
  ColorGrey700 = '#161616',
  ColorGrey600 = '#4A4A4A',
  ColorGrey500 = '#6D6D6D',
  ColorGrey400 = '#A7A7A7',
  ColorGrey300 = '#C1C1C1',
  ColorGrey200 = '#E6E6E6',
  ColorGrey100 = '#F2F2F2',
  ColorGrey50 = '#F8F8F8',
  ColorWhite = '#FFFFFF',
  ColorBeige = '#FAF7F2',
  ColorBeige300 = '#EDE6DB',
  ColorInformation = '#03A7ED',
  ColorInformationSoft = '#E6F9FD',
  ColorSuccess = '#67DB00',
  ColorSuccessSoft = '#F0FBE6',
  ColorWarning = '#FF9200',
  ColorWarningSoft = '#FFFAE6',
  ColorError = '#E52865',
  ColorErrorSoft = '#FBDFE8',
}

export enum Fonts {
  FontGilmer = 'Gilmer',
  FontGilmerOutline = 'Gilmer Outline',
  FontDiagramm = 'Diagramm',
}

export enum Screens {
  ScreenXS = 'xs',
  ScreenS = 'sm',
  ScreenM = 'md',
  ScreenL = 'lg',
  ScreenXL = 'xl',
  ScreenMobile = ScreenS,
  ScreenTablet = ScreenM,
  ScreenDesktop = ScreenXL,
}

export enum Shadows {
  Shadow100 = '0.0px 2.0px 8.0px 0px rgba(79, 90, 109, 0.08)',
  Shadow200 = '0.0px 6.0px 16.0px 0px rgba(79, 90, 109, 0.08)',
  Shadow300 = '0.0px 14.0px 24.0px 0px rgba(79, 90, 109, 0.08)',
  Shadow400 = '0.0px 22.0px 32.0px 0px rgba(79, 90, 109, 0.08)',
}

export enum Spacings {
  SpacingXS = 0.5,
  SpacingS = 1,
  SpacingM = 1.5,
  SpacingL = 2,
  SpacingXL = 3,
  Spacing2XL = 4,
  Spacing3XL = 5,
  Spacing4XL = 6,
  Spacing5XL = 7,
  Spacing6XL = 8,
}
