import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceOneField,
  TextField,
  TextInput,
} from 'react-admin';

const postFilters = [
  <TextInput label="Recherche par code" source="code" />,
  <TextInput label="Recherche par label" source="label" />,
];

export const MediaCategoryList = () => (
  <List filters={postFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="label" label="Label" />
      <TextField source="code" label="Code" />
      <ReferenceOneField
        label="Type de media"
        reference="media-types"
        target="mediaCategories"
      >
        <TextField source="label" />
      </ReferenceOneField>
      <FunctionField render={() => <EditButton label="Modifier" />} />
    </Datagrid>
  </List>
);
