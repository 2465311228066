import { styled } from '@mui/material';

export const StyledFormLabel = styled('label')`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;
