import { Grid } from '@mui/material';
import { ReferenceInput, required } from 'react-admin';

import { FormAutocompleteInput } from '../../ui/components/form/formAutocompleteInput/FormAutocompleteInput';
import { FormLabel } from '../../ui/components/form/formLabel/FormLabel';
import { FormTextInput } from '../../ui/components/form/formTextInput/FormTextInput';
import { AddressType, CountryCode } from './definitions';

export const AddressForm = () => (
  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
    <Grid item xs={4}>
      <FormLabel label="Nom" required />
      <FormTextInput source="name" validate={required()} />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Adresse" required />
      <FormTextInput source="addressLine1" validate={required()} />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Complément d'adresse" />
      <FormTextInput source="addressLine2" />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Ville" required />
      <FormTextInput source="cityName" validate={required()} />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Code postale" required />
      <FormTextInput source="postalCode" validate={required()} />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Code pays" required />
      <FormAutocompleteInput
        source="countryCode"
        validate={required()}
        choices={CountryCode}
      />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Type" required />
      <FormAutocompleteInput
        source="addressType"
        validate={required()}
        choices={AddressType}
      />
    </Grid>
    <Grid item xs={4}>
      <FormLabel label="Structure" required />
      <ReferenceInput source="structure" reference="structures">
        <FormAutocompleteInput
          optionText="name"
          optionValue="@id"
          validate={required()}
        />
      </ReferenceInput>
    </Grid>
  </Grid>
);
