import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FC } from 'react';

import { StyleWrapperAddButton } from './AddButton.style';

interface AddButtonProps {
  onClick: () => void;
  label?: string;
}

export const AddButton: FC<AddButtonProps> = ({
  onClick,
  label = 'Ajouter',
}) => {
  return (
    <StyleWrapperAddButton>
      <Button variant="outlined" onClick={onClick} startIcon={<AddIcon />}>
        {label}
      </Button>
    </StyleWrapperAddButton>
  );
};
