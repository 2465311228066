import { Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import useErrorNotif from '../../hooks/useErrorNotif.hook';
import useSuccessNotif from '../../hooks/useSuccessNotif.hook';
import { FormToolbar } from '../../ui/layout/formToolbar/FormToolbar';

export const ServiceCreate = () => {
  const { successNotif } = useSuccessNotif();
  const { errorNotif } = useErrorNotif();

  return (
    <Create
      sx={{ maxWidth: 500 }}
      mutationOptions={{
        onError: errorNotif,
        onSuccess: () => successNotif('Service créé', 'services'),
      }}
    >
      <SimpleForm toolbar={<FormToolbar />}>
        <Typography variant="h3" gutterBottom>
          Ajouter un service
        </Typography>
        <TextInput fullWidth source="name" label="Nom" validate={required()} />
        <TextInput
          fullWidth
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceArrayInput source="clients" reference="clients">
          <AutocompleteArrayInput
            optionText="companyName"
            optionValue="@id"
            fullWidth
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
