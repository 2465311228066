import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { PimItemCreate } from './PimItemCreate';
import { PimItemEdit } from './PimItemEdit';
import { PimItemList } from './PimItemList';
export default {
  icon: BusinessCenterIcon,
  create: PimItemCreate,
  edit: PimItemEdit,
  list: PimItemList,
  recordRepresentation: ({ id }: { id: string }) => id,
};
